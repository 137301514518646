import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import DdjsListUtil ,{IDdjsListDataObj} from './ddjsListUtil';

export default defineComponent ({
    name: 'DdjsList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IDdjsListDataObj=reactive<IDdjsListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                gridTitle:'订单结算',
                queryParam: {},
                cardFrom:'DdjsList',
                isShowFixCol:false,
                modelMethod: utils.OrderProviderApi.buildUrl('/ddjs/pageData')
            },
            otherParams:{
                count:0,
                dialogTitle:'订单结算',
                dialogVisible:false,
                selInfo:{
                    F_PI_ID:'',//piId
                    F_ORDER_CODE:'',//订单号
                    F_TOTAL_AMOUNT:0,//总金额
                },
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new DdjsListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {

            }
        })
        return{
            ...toRefs(dataObj),comboSelect
        }
    }
});