import BaseBean from "@/utils/BaseBean";
import PiCard from "@/views/project/order/order/pi/PiCard.vue";
import XyzCard from "@/views/project/order/finance/xyz/xyz/XyzCard.vue";
import OaApplyCard from "@/views/project/order/finance/oa/oaApply/OaApplyCard.vue";
import TtPayCard from "@/views/project/order/finance/tt/ttPay/TtPayCard.vue";
export interface IDdjsListDataObj {
    utilInst:DdjsListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class DdjsListUtil extends BaseBean{
    public dataObj:IDdjsListDataObj

    constructor(proxy:any,dataObj:IDdjsListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{

    }
    //订单结算
    public async ddjsHandler():Promise<any>{
        let selections:Array<any>=this.dataObj.pageListRef.getTbInst().getSelection();
        if(selections.length==0){
            this.proxy.$message('请选择要操作的行');
            return ;
        }
        this.dataObj.otherParams.selInfo.F_PI_ID=selections[0].F_PI_ID;
        this.dataObj.otherParams.selInfo.F_ORDER_CODE=selections[0].F_ORDER_CODE;
        this.dataObj.otherParams.selInfo.F_TOTAL_AMOUNT=selections[0].F_TOTAL_AMOUNT;
        this.dataObj.otherParams.dialogVisible=true;
    }
    //查看pi弹出框
    public async piDetail(piId:string):Promise<any>{
        await this.utils.UtilPub.openDialog({proxy:this.proxy,addOrEdit:'edit',id:piId,modelComp:PiCard,ownerComp:this.proxy,cardFrom:'SkqrList',
            title:'pi详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
    }
    //订单结算发起请求处理
    public async doDdjs():Promise<any>{
        let selections:Array<any>=this.dataObj.pageListRef.getTbInst().getSelection();
        let params={id:selections[0].F_ID};
        let res=await this.utils.OrderProviderApi.ddjsAction({params:params});
        if(res.result){
            this.dataObj.pageListRef.queryHandler(true);
            this.utils.Tools.success();
            this.dataObj.otherParams.dialogVisible=false;
        }
    }

}